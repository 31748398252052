function NavBar(){
    return(
        <nav class="navbar app">
            <div class="container-fluid">
                <a class="navbar-brand title">T-ester</a>
            </div>
        </nav>
    )
}

export default NavBar;